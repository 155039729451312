<template>
  <!-- 普通车票 选时间、车站 -->
  <div>
    <!-- UI + 逻辑 -->
    <BuyTrainUI @searchTrain="searchTrain" @chooseTrainStation="chooseTrainStation" />
    <!-- 历史记录 -->
    <History ref="history" :historyKey="'trainHistory'" @setCity="setCity" />
  </div>
</template>

<script>
import BuyTrainUI from "./components/BuyTrainUI";
import History from "@/components/History";
import { mapGetters } from "vuex";
export default {
  name: "BuyTrain",
  components: { BuyTrainUI, History },
  computed: {
    ...mapGetters(["orderInfo"])
  },
  methods: {
    // 搜索按钮
    searchTrain() {
      this.$refs.history.append({
        to: this.orderInfo.trainToStation,
        from: this.orderInfo.trainFromStation
      });
      this.$router.push("/searchTrain");
    },

    // 选择城市  参数是出发、到达城市的vuex方法名，丢到query即可
    chooseTrainStation(vuxFunction) {
      this.$router.push({
        path: "/selectCity",
        query: { function: vuxFunction }
      });
    },

    // 历史记录选择城市
    setCity(data) {
      this.orderInfo.trainToStation = data.to;
      this.orderInfo.trainFromStation = data.from;
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
    }
  }
};
</script>
